<template>
  <v-container fluid>
    <v-tabs v-model="tab">
      <v-tab v-for="group in filterGroups" :key="group.value">
        {{ group.text }}
      </v-tab>
    </v-tabs>

    <v-card class="pa-5 mt-3">
      <v-data-table
        :headers="headers"
        :items="charges"
        :search="search"
        class=""
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" persistent max-width="600px">
              <template v-slot:activator="{ on, attrs }" v-if="isInRole('54')">
                <v-btn
                  v-if="isInRole('54')"
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t("add") }}

                  <v-icon> mdi-plus </v-icon>
                </v-btn>
              </template>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-card>
                  <v-toolbar color="primary darken-1" dark>
                    <v-card-title>
                      <span class="">{{
                        (editedIndex === -1 ? $t("add") : $t("edit")) +
                          " " +
                          $t("services.serviceCharge")
                      }}</span>
                    </v-card-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.serviceGuid"
                            :rules="rules"
                            :items="services"
                            :label="$t('services.serviceName')"
                            required
                            item-text="serviceName"
                            item-value="guid"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-autocomplete
                            v-model="editedItem.doctorGuid"
                            :items="doctors"
                            :label="$t('doctors.doctorName')"
                            required
                            item-text="doctorName"
                            item-value="guid"
                          ></v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.servicePrice"
                            :label="$t('services.servicePrice')"
                            type="number"
                            :rules="rules"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                            v-model="editedItem.doctorAmount"
                            :label="$t('services.doctorAmount')"
                            type="number"
                            min="0"
                            :rules="[
                              (value) =>
                                !!value ||
                                value == 0 ||
                                $t('ThisFieldIsRequired'),
                            ]"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="11" md="11">
                          <!-- <v-text-field
                          v-model="editedItem.doctorPercentage"
                          label="Doctor Percentage"
                        ></v-text-field> -->

                          <!-- <v-subheader class="Percentage"
                          >Doctor Percentage</v-subheader
                        > -->
                          <!-- <v-card-text class="pa-1"> -->
                          <v-slider
                            v-model="editedItem.doctorPercentage"
                            color="orange"
                            min="0"
                            max="100"
                            persistent-hint
                            :label="$t('services.doctorPercentage')"
                            :thumb-size="24"
                            thumb-label="always"
                          ></v-slider>
                          <!-- </v-card-text> -->
                        </v-col>
                        <v-col
                          cols="1"
                          md="1"
                          class="pa-0 pt-3 font-weight-medium body-1"
                        >
                          <div>{{ editedItem.doctorPercentage + " %" }}</div>
                        </v-col>

                        <v-col cols="12" class="pt-0">
                          <v-textarea
                            v-model="editedItem.serviceChargeNote"
                            :label="$t('notes')"
                            rows="2"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn
                      color="green darken-1"
                      class="white--text"
                      @click="save"
                      :disabled="!valid"
                      :min-width="100"
                    >
                      <v-icon>mdi-content-save-outline</v-icon>
                      {{ $t("save") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="close">
                      {{ $t("cancel") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
            <confirm-dialog
              :openDialog="dialogDelete"
              :onClicked="deleteItemConfirm"
              :onClose="closeDelete"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="text-end">
            <v-tooltip top v-if="isInRole('55')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("edit") }} </span>
            </v-tooltip>
            <v-tooltip top v-if="isInRole('56')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  @click="deleteItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ $t("delete") }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`item.serviceGroup`]="{ item }">
          <v-chip class="ma-2"> {{ getText(item.serviceGroup) }} </v-chip>
        </template>

        <template v-slot:[`item.doctorName`]="{ item }">
          <span v-if="item.doctorName">
            {{ item.doctorName }}
          </span>
          <span v-else> {{ $t("services.allDoctor") }} </span>
        </template>

        <template v-slot:[`item.doctorPercentage`]="{ item }">
          <v-chip class="ma-2"> {{ item.doctorPercentage }}% </v-chip>
        </template>
      </v-data-table>
      <v-divider />
      <v-row class="mt-2">
        <v-col cols="2"></v-col>

        <v-col align-self="end">
          <v-pagination
            class="mb-2 "
            v-model="page"
            :length="pageLength ? pageLength : 0"
          ></v-pagination>
        </v-col>
        <v-col align-self="center" cols="2">
          <v-select
            dense
            class="pa-1"
            solo
            :label="$t('itemPerPage')"
            :items="itemPerPageSelect"
            item-text="text"
            item-value="value"
            v-model="itemsPerPage"
            hide-details=""
          >
          </v-select>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
export default {
  components: { ConfirmDialog },
  data() {
    return {
      page: 1,
      itemsPerPage: 5,
      itemPerPageSelect: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "15", value: 15 },
        { text: this.$t("all"), value: -1 },
      ],
      tab: 0,

      valid: true,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      search: "",
      headers: [
        { text: this.$t("services.serviceName"), value: "serviceName" },
        { text: this.$t("doctors.doctorName"), value: "doctorName" },
        { text: this.$t("services.servicePrice"), value: "servicePrice" },
        { text: this.$t("services.doctorAmount"), value: "doctorAmount" },
        {
          text: this.$t("services.doctorPercentage"),
          value: "doctorPercentage",
        },
        { text: this.$t("notes"), value: "serviceChargeNote" },
        { text: "", value: "actions" },
      ],
      charges: [],
      editedItem: {
        serviceGuid: "",
        doctorGuid: "",
        servicePrice: null,
        doctorAmount: null,
        doctorPercentage: 0,
        serviceChargeNote: "",
      },
      defaultItem: {
        serviceGuid: "",
        doctorGuid: "",
        servicePrice: null,
        doctorAmount: null,
        doctorPercentage: 0,
        serviceChargeNote: "",
      },

      groups: [
        {
          text: this.$t("services.serviceGroupType.general"),
          value: 0,
          role: "65",
        },
        {
          text: this.$t("services.serviceGroupType.dental"),
          value: 1,
          role: "66",
        },
        {
          text: this.$t("services.serviceGroupType.operation"),
          value: 2,
          role: "67",
        },
      ],
      services: [],
      doctors: [],
      rules: [(value) => !!value || this.$t("ThisFieldIsRequired")],
    };
  },
  created() {
    this.refreshTable();
  },

  watch: {
    dialog(val) {
      if (!val) this.close();

      if (this.editedIndex == -1) this.editedItem.doctorAmount = 0;
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    tab() {
      this.refreshTable();
    },
    pageLength(val) {
      if (this.page > val) this.page = val;
    },
  },
  computed: {
    filterGroups() {
      return this.groups.filter((f) => this.isInRole(f.role));
    },
    pageLength() {
      if (this.itemsPerPage == -1) {
        return 1;
      } else {
        var div = this.charges.length / this.itemsPerPage;
        return Number.isInteger(div) ? div : (div | 0) + 1;
      }
    },
  },
  methods: {
    getText(i) {
      var result = this.groups.filter((obj) => {
        return obj.value == i;
      });
      return result[0].text;
    },
    editItem(item) {
      this.editedIndex = this.charges.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.charges.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      var deleteItem = this.charges[this.editedIndex];

      axios
        .delete("ServiceCharge/Delete?guid=" + deleteItem.guid)
        .then((response) => {
          if (response.status == 200) this.refreshTable();
        });

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    refreshTable() {
      axios
        .get("ServiceCharge/Get?group=" + this.filterGroups[this.tab].value)
        .then((response) => {
          this.charges = response.data.data;

          if (this.isInRole("6")) {
            axios.get("doctor").then((response) => {
              this.doctors = response.data.data;
              this.doctors.push({
                guid: "",
                doctorName: this.$t("services.allDoctor"),
              });
            });
          } else {
            this.doctors.push({
              guid: "",
              doctorName: this.$t("services.allDoctor"),
            });
          }

          axios
            .get("service/Get?group=" + this.filterGroups[this.tab].value)
            .then((response) => {
              this.services = response.data.data;
            });
        });
    },

    save() {
      var val = this.$refs.form.validate();
      if (val) {
        if (this.editedIndex > -1) {
          var updatedItem = this.charges[this.editedIndex];
          updatedItem.serviceGuid = this.editedItem.serviceGuid;
          updatedItem.doctorGuid = this.editedItem.doctorGuid;
          updatedItem.servicePrice = this.editedItem.servicePrice;
          updatedItem.doctorAmount = this.editedItem.doctorAmount;
          updatedItem.doctorPercentage = this.editedItem.doctorPercentage;
          updatedItem.serviceChargeNote = this.editedItem.serviceChargeNote;

          axios
            .post("ServiceCharge/Update", updatedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });

          Object.assign(this.charges[this.editedIndex], this.editedItem);
        } else {
          axios
            .post("ServiceCharge/Add", this.editedItem)
            .then((response) => {
              if (response.data.status == "Successful") {
                this.$toast(this.$t("operationAccomplishedSuccessfully"));
                this.close();
              } else {
                this.$toast.error(this.$t("error." + response.data.message));
              }

              this.refreshTable();
            })
            .catch((e) => {
              this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
              console.log(e);
            });
        }
      }
    },
  },
};
</script>

<style scoped>
.Percentage {
  height: unset;
  padding: 0;
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
