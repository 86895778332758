var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.filterGroups),function(group){return _c('v-tab',{key:group.value},[_vm._v(" "+_vm._s(group.text)+" ")])}),1),_c('v-card',{staticClass:"pa-5 mt-3"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.charges,"search":_vm.search,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([(_vm.isInRole('54'))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isInRole('54'))?_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("add"))+" "),_c('v-icon',[_vm._v(" mdi-plus ")])],1):_vm._e()]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary darken-1","dark":""}},[_c('v-card-title',[_c('span',{},[_vm._v(_vm._s((_vm.editedIndex === -1 ? _vm.$t("add") : _vm.$t("edit")) + " " + _vm.$t("services.serviceCharge")))])])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"rules":_vm.rules,"items":_vm.services,"label":_vm.$t('services.serviceName'),"required":"","item-text":"serviceName","item-value":"guid"},model:{value:(_vm.editedItem.serviceGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "serviceGuid", $$v)},expression:"editedItem.serviceGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.doctors,"label":_vm.$t('doctors.doctorName'),"required":"","item-text":"doctorName","item-value":"guid"},model:{value:(_vm.editedItem.doctorGuid),callback:function ($$v) {_vm.$set(_vm.editedItem, "doctorGuid", $$v)},expression:"editedItem.doctorGuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('services.servicePrice'),"type":"number","rules":_vm.rules},model:{value:(_vm.editedItem.servicePrice),callback:function ($$v) {_vm.$set(_vm.editedItem, "servicePrice", $$v)},expression:"editedItem.servicePrice"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('services.doctorAmount'),"type":"number","min":"0","rules":[
                            function (value) { return !!value ||
                              value == 0 ||
                              _vm.$t('ThisFieldIsRequired'); } ]},model:{value:(_vm.editedItem.doctorAmount),callback:function ($$v) {_vm.$set(_vm.editedItem, "doctorAmount", $$v)},expression:"editedItem.doctorAmount"}})],1),_c('v-col',{attrs:{"cols":"11","md":"11"}},[_c('v-slider',{attrs:{"color":"orange","min":"0","max":"100","persistent-hint":"","label":_vm.$t('services.doctorPercentage'),"thumb-size":24,"thumb-label":"always"},model:{value:(_vm.editedItem.doctorPercentage),callback:function ($$v) {_vm.$set(_vm.editedItem, "doctorPercentage", $$v)},expression:"editedItem.doctorPercentage"}})],1),_c('v-col',{staticClass:"pa-0 pt-3 font-weight-medium body-1",attrs:{"cols":"1","md":"1"}},[_c('div',[_vm._v(_vm._s(_vm.editedItem.doctorPercentage + " %"))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('notes'),"rows":"2"},model:{value:(_vm.editedItem.serviceChargeNote),callback:function ($$v) {_vm.$set(_vm.editedItem, "serviceChargeNote", $$v)},expression:"editedItem.serviceChargeNote"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"white--text",attrs:{"color":"green darken-1","disabled":!_vm.valid,"min-width":100},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[(_vm.isInRole('55'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])]):_vm._e(),(_vm.isInRole('56'))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])]):_vm._e()],1)]}},{key:"item.serviceGroup",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.getText(item.serviceGroup))+" ")])]}},{key:"item.doctorName",fn:function(ref){
                          var item = ref.item;
return [(item.doctorName)?_c('span',[_vm._v(" "+_vm._s(item.doctorName)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("services.allDoctor"))+" ")])]}},{key:"item.doctorPercentage",fn:function(ref){
                          var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(item.doctorPercentage)+"% ")])]}}],null,true)}),_c('v-divider'),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{attrs:{"align-self":"end"}},[_c('v-pagination',{staticClass:"mb-2 ",attrs:{"length":_vm.pageLength ? _vm.pageLength : 0},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"align-self":"center","cols":"2"}},[_c('v-select',{staticClass:"pa-1",attrs:{"dense":"","solo":"","label":_vm.$t('itemPerPage'),"items":_vm.itemPerPageSelect,"item-text":"text","item-value":"value","hide-details":""},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }